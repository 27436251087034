import { Button, Flex, Grid, useColorModeValue } from '@chakra-ui/react';
import { getAllCaracteristica, getAllTipo } from 'api/cafe';
import { addEntrada, getLatestId } from 'api/entradas';
import { getAllOrigen, getAllProductor } from 'api/origen';
import ResultMessage from 'components/modal/ResultMessage';
import { VSeparator } from 'components/separator/Separator';
import { ALERT } from 'constant/constant';
import { useEffect, useState } from 'react';
import { MdOutlineDriveFileMove } from 'react-icons/md';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { cafeActions } from 'store/cafe';
import { origenActions } from 'store/origen';
import { getCosecha } from 'utils/format';
import Proveedor from 'views/admin/seco/entradas/components/Proveedor/Proveedor';
import PrintableContent from './PrintableContent';
import Peso from './components/Peso por unidad o transporte/Peso';

export default function Registro() {
  const TOKEN = useSelector((state) => state.auth.tokenId);
  const USERID = useSelector((state) => state.auth.userId);

  const dispatch = useDispatch();
  const location = useLocation();

  const { selectedEntrada, previewMode, editMode } = location.state || {};

  const paleGray = useColorModeValue('secondaryGray.400', 'whiteAlpha.100');
  const balanceBg = useColorModeValue('brand.900', '#1B254B');

  const { productor, origen } = useSelector((state) => state.origen);
  const { tipo, caracteristica } = useSelector((state) => state.cafe);

  const initialWeightEntriesState = [
    {
      id: 1,
      chofer: 'Jorge Cruz Vázquez',
      placas: 'RY-30-570',
      marca: 'Ford',
      unidad: '1',
      cumple: 'Cumple',
      caracteristica: '',
      ticketBascula: '',
      pesoConCarga: '0.00',
      pesoSinCarga: '0.00',
      yutes: '0',
      cajas: '0',
      lonas: '0',
      tara: '0',
      pesoBruto: '0.00',
      pesoNeto: '0.00',
      pesoPorSaco: '0.00',
      humedad: '0.00',
      descuentoHumedad: '0.00',
      descuentoHumedadPeso: '0.00',
      precioPrevio: '0.00',
      precioKg: '0.00',
      precioTotal: '0.00',
      observaciones: '',
    },
  ];

  const initialEntradaState = {
    entradaId: '',
    fecha: new Date(),
    estado: '',
    region: '',
    municipio: '',
    comunidad: '',
    productor: '',
    cosecha: getCosecha(),
    tipo: '',
  };

  const [weightEntries, setWeightEntries] = useState(initialWeightEntriesState);
  const [entrada, setEntrada] = useState(initialEntradaState);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [message, setMessage] = useState({
    state: false,
    title: '',
    subtitle: '',
  });

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [
          responseOrigen,
          responseProductor,
          responseTipo,
          responseCaracteristica,
        ] = await Promise.all([
          getAllOrigen(TOKEN),
          getAllProductor(TOKEN),
          getAllTipo(TOKEN),
          getAllCaracteristica(TOKEN),
        ]);

        if (responseOrigen.status === 200) {
          const resultOrigen = await responseOrigen.json();
          const resultDataOrigen = resultOrigen?.data?.data || [];
          dispatch(origenActions.setOrigenData({ origen: resultDataOrigen }));
        }

        if (responseProductor.status === 200) {
          const resultProductor = await responseProductor.json();
          const resultDataProductor = resultProductor?.data?.data || [];
          dispatch(
            origenActions.setProductorData({ productor: resultDataProductor }),
          );
        }

        if (responseTipo.status === 200) {
          const resultTipo = await responseTipo.json();
          const resultDataTipo = resultTipo?.data?.data || [];
          dispatch(cafeActions.setTipoData({ tipo: resultDataTipo }));
        }

        if (responseCaracteristica.status === 200) {
          const resultCaracteristica = await responseCaracteristica.json();
          const resultDataCaracteristica =
            resultCaracteristica?.data?.data || [];
          dispatch(
            cafeActions.setCaracteristicaData({
              caracteristica: resultDataCaracteristica,
            }),
          );
        }

        const latestId = await getLatestId(TOKEN);
        if (latestId !== null && latestId !== undefined) {
          setEntrada((prevState) => ({
            ...prevState,
            entradaId: latestId + 1,
          }));
        } else {
          handleMessage(
            'error',
            ALERT.ERROR_TITLE,
            ALERT.ERROR_SUBTITLE,
            false,
          );
        }
      } catch (error) {
        console.error(error);
        handleMessage('error', ALERT.ERROR_TITLE, ALERT.ERROR_SUBTITLE, false);
      }
    };

    fetchData();
  }, [TOKEN, dispatch]);

  const handleWeightEntryChange = (field, value, id) => {
    setWeightEntries((prevState) => {
      return prevState.map((item) => {
        if (item?.id === id) {
          return {
            ...item,
            [field]: value,
          };
        }
        return item;
      });
    });
  };

  const handleChange = (field, value) => {
    setEntrada((prevState) => ({
      ...prevState,
      [field]: value,
    }));
  };

  const handleMessage = (state, msg, sub) => {
    setMessage({
      state: state,
      title: msg,
      subtitle: sub,
    });
  };

  const handleFocus = () => {
    setMessage({
      state: false,
      title: '',
      subtitle: '',
    });
    setIsSubmitted(false);
  };

  const handleCloseModal = () => {
    setMessage({
      state: false,
      title: '',
      subtitle: '',
    });
  };

  const handleAllTruthy = (obj, exceptions = []) => {
    const arrayOfObjects = Array.isArray(obj) ? obj : [obj];
    const values = arrayOfObjects.flatMap((item) => {
      const keys = Object.keys(item);
      const filteredKeys = keys.filter((key) => !exceptions.includes(key));
      return filteredKeys.map((key) => item[key]);
    });

    if (!values || values.length === 0) {
      return false;
    } else {
      return values.every((value) => !!value);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const exceptions = [
      'observaciones',
      'lonas',
      'yutes',
      'tara',
      'cajas',
      'descuentoHumedad',
      'descuentoHumedadPeso',
    ];

    const allEntradaPropertiesTruthy = handleAllTruthy(entrada, exceptions);
    const allWeightEntriesPropertiesTruthy = handleAllTruthy(
      weightEntries,
      exceptions,
    );

    if (allEntradaPropertiesTruthy && allWeightEntriesPropertiesTruthy) {
      try {
        setIsLoading(true);
        setIsSubmitted(true);

        const totalYutes = weightEntries.reduce(
          (sum, entry) => sum + parseFloat(entry.yutes || 0),
          0,
        );
        const totalLonas = weightEntries.reduce(
          (sum, entry) => sum + parseFloat(entry.lonas || 0),
          0,
        );
        const totalCajas = weightEntries.reduce(
          (sum, entry) => sum + parseFloat(entry.cajas || 0),
          0,
        );
        const totalTara = weightEntries.reduce(
          (sum, entry) => sum + parseFloat(entry.tara || 0),
          0,
        );
        const totalPrecioPrevio = weightEntries.reduce(
          (sum, entry) => sum + parseFloat(entry.precioPrevio || 0),
          0,
        );
        const totalDescuentoHumedadPeso = weightEntries.reduce(
          (sum, entry) => sum + parseFloat(entry.descuentoHumedadPeso || 0),
          0,
        );
        const totalPrecioTotal = weightEntries.reduce(
          (sum, entry) => sum + parseFloat(entry.precioTotal || 0),
          0,
        );
        const totalDescuentoHumedad = weightEntries.reduce(
          (sum, entry) => sum + parseFloat(entry.descuentoHumedad || 0),
          0,
        );
        const totalPesoNeto = weightEntries.reduce(
          (sum, entry) => sum + parseFloat(entry.pesoNeto || 0),
          0,
        );
        const totalPesoBruto = weightEntries.reduce(
          (sum, entry) => sum + parseFloat(entry.pesoBruto || 0),
          0,
        );
        const entradaEntryTotal = {
          ...entrada,
          registerId: entrada.entradaId + '.0',
          cajas: totalCajas.toFixed(2) || '0.00',
          lonas: totalLonas.toFixed(2) || '0.00',
          yutes: totalYutes.toFixed(2) || '0.00',
          tara: totalTara.toFixed(2) || '0.00',
          pesoBruto: totalPesoBruto.toFixed(2) || '0.00',
          pesoNeto: totalPesoNeto.toFixed(2) || '0.00',
          descuentoHumedad: totalDescuentoHumedad.toFixed(2) || '0.00',
          descuentoHumedadPeso: totalDescuentoHumedadPeso.toFixed(2) || '0.00',
          precioPrevio: totalPrecioPrevio.toFixed(2) || '0.00',
          precioTotal: totalPrecioTotal.toFixed(2) || '0.00',
        };

        // Add entradaEntryTotal at the beginning of the weightEntries array
        const updatedWeightEntries = [entradaEntryTotal, ...weightEntries];

        const responses = await Promise.all(
          updatedWeightEntries.map(async (entry) => {
            const submitEntrada = { ...entrada, ...entry };
            return await addEntrada(TOKEN, USERID, submitEntrada);
          }),
        );

        const allSuccessful = responses.every(
          (response) => response.status === 200,
        );

        if (allSuccessful) {
          setIsSubmitted(false);
          setIsLoading(false);
          handleMessage('success', ALERT.SUCCESS_TITLE, ALERT.SUCCESS_SUBTITLE);
          setWeightEntries(initialWeightEntriesState);
          setEntrada((prevState) => ({
            ...initialEntradaState,
            entradaId: prevState.entradaId + 1,
          }));
          window.scrollTo({ top: 0, left: 0 });
        } else {
          setIsLoading(false);
          handleMessage('error', ALERT.ERROR_TITLE, ALERT.ERROR_SUBTITLE);
        }
      } catch (error) {
        setIsLoading(false);
        handleMessage('error', ALERT.ERROR_TITLE, ALERT.ERROR_SUBTITLE);
        console.error(error);
      }
    } else {
      setIsSubmitted(true);
      handleMessage('error', ALERT.ERROR_TITLE, ALERT.ERROR_FIELDS);
    }
  };

  return !previewMode ? (
    <Flex
      direction={{ base: 'column', xl: 'row' }}
      pt={{ base: '130px', md: '80px', xl: '80px' }}
    >
      <Flex direction="column" width="stretch">
        <Grid
          mb="20px"
          gridTemplateColumns={{ base: 'repeat(2, 1fr)' }}
          gap="20px"
          display={{ base: 'block', lg: 'grid' }}
        >
          <Flex gridArea={{ base: '2 / 1 / 3 / 3' }}>
            <Proveedor
              entrada={entrada}
              setEntrada={setEntrada}
              isSubmitted={isSubmitted}
              handleFocus={handleFocus}
              handleChange={handleChange}
              origen={origen}
              productor={productor}
              tipo={tipo}
            />
          </Flex>
        </Grid>
        <Grid
          mb="20px"
          gridTemplateColumns={{ base: 'repeat(2, 1fr)' }}
          gap="20px"
          display={{ base: 'block', lg: 'grid' }}
        >
          <Flex gridArea={{ base: '2 / 1 / 3 / 3' }}>
            <Peso
              key={entrada.entradaId}
              isSubmitted={isSubmitted}
              handleFocus={handleFocus}
              handleWeightEntryChange={handleWeightEntryChange}
              weightEntries={weightEntries}
              setWeightEntries={setWeightEntries}
              caracteristica={caracteristica}
            />
          </Flex>
        </Grid>
        <Flex justify="center" my="40px" gap="25px">
          <Button
            onClick={handleSubmit}
            leftIcon={<MdOutlineDriveFileMove />}
            isLoading={isLoading}
            loadingText="Realizando registro..."
            bgColor={balanceBg}
            color="white"
            size="lg"
          >
            Registrar entrada
          </Button>
        </Flex>
      </Flex>
      <VSeparator
        mx="20px"
        bg={paleGray}
        display={{ base: 'none', xl: 'flex' }}
      />
      <ResultMessage
        isOpen={message.state}
        onClose={handleCloseModal}
        message={message}
      />
    </Flex>
  ) : (
    <PrintableContent
      entrada={entrada}
      selectedEntrada={selectedEntrada}
      weightEntries={weightEntries}
      previewMode={previewMode}
    />
  );
}
