// Chakra imports
import {
  Checkbox,
  Flex,
  FormLabel,
  SimpleGrid,
  Stack,
  Text,
  useColorModeValue,
} from '@chakra-ui/react';

// Custom components
import Card from 'components/card/Card.js';

// Assets
import balanceImg from 'assets/img/dashboards/balanceImg.png';
import { MdOutlineMonitorWeight } from 'react-icons/md';
import { formatTableValue } from 'utils/format';

export default function PesoPrintable({ weightEntries, previewMode }) {
  const balanceBg = useColorModeValue('brand.900', 'white');
  const textColor = useColorModeValue('secondaryGray.500', 'white');

  const vehicleForm = (weightEntry) => {
    const weightEntryValues = [
      {
        title: 'Operador / Chofer',
        value: weightEntry?.chofer || 'No especificado',
      },
      { title: 'Placas', value: weightEntry?.placas || 'No especificado' },
      { title: 'Marca', value: weightEntry?.marca || 'No especificado' },
      {
        title: 'Número de unidad',
        value: weightEntry?.unidad || 'No especificado',
      },
    ];

    return (
      <>
        <SimpleGrid
          columns={{ base: '4', md: '4', lg: '4' }}
          gap={{ sm: '10px', md: '26px' }}
          mb={'1rem'}
          px={'2rem'}
        >
          {weightEntryValues.map((x, index) => (
            <Flex key={index} direction="column" mb="1rem">
              <Flex>
                <FormLabel
                  display="flex"
                  fontSize="sm"
                  fontWeight="500"
                  color={textColor}
                >
                  {x.title}
                </FormLabel>
              </Flex>
              <Text>{x.value}</Text>
            </Flex>
          ))}
        </SimpleGrid>
        <SimpleGrid
          columns={{ base: '2' }}
          gap={{ sm: '10px', md: '26px' }}
          mb={'1rem'}
          px={'2rem'}
        >
          <Flex direction="column" mb="1rem">
            <Text fontSize="md" fontWeight="500" color={textColor}>
              Camión en condiciones de sanidad exterior e interior, en piso
              cubierta y techo
            </Text>
          </Flex>
          <Flex direction="column" mb="1rem">
            <Stack spacing={5} direction="row">
              <Checkbox
                isChecked={weightEntry?.cumple === 'Cumple'}
                color={textColor}
              >
                Cumple
              </Checkbox>
              <Checkbox
                isChecked={weightEntry?.cumple === 'No cumple'}
                color={textColor}
              >
                No cumple
              </Checkbox>
            </Stack>
          </Flex>
        </SimpleGrid>
      </>
    );
  };

  const pesoForm = (weightEntry) => {
    debugger;
    const weightEntryValues = [
      {
        title: 'Característica de café',
        value: weightEntry?.caracteristica || 'No especificado',
        type: '',
      },
      {
        title: 'Número de ticket báscula',
        value: weightEntry?.ticketBascula || 'No especificado',
        type: '',
      },
      {
        title: 'Peso con carga',
        value: weightEntry?.pesoConCarga || 'No especificado',
        type: weightEntry?.pesoConCarga ? 'kilograms' : '',
      },
      {
        title: 'Peso sin carga',
        value: weightEntry?.pesoSinCarga || 'No especificado',
        type: weightEntry?.pesoSinCarga ? 'kilograms' : '',
      },
      {
        title: 'Yutes',
        value: weightEntry?.yutes || 'No especificado',
        type: '',
      },
      {
        title: 'Lonas',
        value: weightEntry?.lonas || 'No especificado',
        type: '',
      },
      {
        title: 'Cajas',
        value: weightEntry?.cajas || 'No especificado',
        type: '',
      },
      {
        title: 'Tara',
        value: weightEntry?.tara || 'No especificado',
        type: weightEntry?.tara ? 'kilograms' : '',
      },
      {
        title: 'Peso bruto',
        value: weightEntry?.pesoBruto || 'No especificado',
        type: weightEntry?.pesoBruto ? 'kilograms' : '',
      },
      {
        title: 'Peso neto',
        value: weightEntry?.pesoNeto || 'No especificado',
        type: weightEntry?.pesoNeto ? 'kilograms' : '',
      },
      {
        title: 'Peso por saco',
        value: weightEntry?.pesoPorSaco || 'No especificado',
        type: weightEntry?.pesoPorSaco ? 'kilograms' : '',
      },
      {
        title: 'Precio por KG',
        value: weightEntry?.precioKg || 'No especificado',
        type: weightEntry?.precioKg ? 'pesos' : '',
      },
      {
        title: 'Descuento humedad (MXN)',
        value: weightEntry?.descuentoHumedad || 'No especificado',
        type: weightEntry?.descuentoHumedad ? 'pesos' : '',
      },
      {
        title: 'Descuento humedad (KG)',
        value: weightEntry?.descuentoHumedadPeso || 'No especificado',
        type: weightEntry?.descuentoHumedadPeso ? 'kilograms' : '',
      },
      {
        title: 'Total a pagar',
        value: weightEntry?.precioTotal || 'No especificado',
        type: weightEntry?.precioTotal ? 'pesos' : '',
      },
      {
        title: 'Observaciones',
        value: weightEntry?.observaciones || 'No especificado',
        type: '',
      },
    ];

    return (
      <SimpleGrid
        columns={{ base: '4', md: '4', lg: '4' }}
        gap={{ sm: '10px', md: '26px' }}
        mb={'1rem'}
        px={'2rem'}
      >
        {weightEntryValues.map((x, index) => (
          <Flex key={index} direction="column" mb="1rem">
            <Flex>
              <FormLabel
                display="flex"
                fontSize="sm"
                fontWeight="500"
                color={textColor}
              >
                {x.title}
              </FormLabel>
            </Flex>
            <Text>{formatTableValue(x.type, x.value)}</Text>
          </Flex>
        ))}
      </SimpleGrid>
    );
  };

  return (
    <Card direction="column" w="100%">
      <Flex
        justify="space-between"
        p="20px"
        mb="20px"
        borderRadius="16px"
        borderWidth="2px"
        borderColor={balanceBg}
        bgImage={balanceImg}
        bgPosition="right"
        bgSize="cover"
        className="printable-subheader"
      >
        <Flex align="center" justify="space-between" w="100%">
          <Flex gap="15px" flexDirection="row" me="14px">
            <MdOutlineMonitorWeight color={balanceBg} size={'25px'} />
            <Text
              color={balanceBg}
              fontSize="20px"
              fontWeight="700"
              lineHeight="100%"
            >
              Peso por unidad o transporte
            </Text>
          </Flex>
        </Flex>
      </Flex>

      {weightEntries?.map((x) => (
        <>
          {!previewMode && (
            <Flex
              align="center"
              justify="space-between"
              w="100%"
              mb={'1rem'}
              px={'25px'}
            >
              <Flex alignItems="center" flexDirection="row" me="14px">
                <Text
                  color={textColor}
                  fontSize="22px"
                  fontWeight="700"
                  lineHeight="100%"
                >
                  {'Registro ' + x.id}
                </Text>
              </Flex>
            </Flex>
          )}
          {vehicleForm(x)}
          {pesoForm(x)}
        </>
      ))}
    </Card>
  );
}
