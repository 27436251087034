// Chakra imports
import {
  Flex,
  FormControl,
  FormLabel,
  Input,
  InputGroup,
  Select,
  SimpleGrid,
  Text,
  useColorModeValue,
} from '@chakra-ui/react';

// Custom components
import Card from 'components/card/Card.js';

// Assets
import balanceImg from 'assets/img/dashboards/balanceImg.png';
import { MdCenterFocusWeak } from 'react-icons/md';

export default function Descarga({
  entradas,
  secado,
  setSecado,
  isSubmitted,
  handleFocus,
  setSecadoEntries,
  latestId,
}) {
  const balanceBg = useColorModeValue('brand.900', '#1B254B');
  const textColor = useColorModeValue('secondaryGray.500', 'white');

  const uniqueEntradas = entradas.filter(
    (entry, index, self) =>
      index === self.findIndex((e) => e.entradaId === entry.entradaId),
  );

  const sumTotalBySpecificId = (specificId) => {
    const total = entradas
      .filter((obj) => obj.entradaId == specificId)
      .reduce(
        (accumulator, currentObject) => accumulator + +currentObject.pesoNeto,
        0,
      );

    return total;
  };

  const handleEntradaSelection = (e) => {
    const selectedOption = e.target.options[e.target.selectedIndex];
    const totalWeight = sumTotalBySpecificId(e.target.value.trim());
    const totalFoliosSecado = Math.ceil(totalWeight / 1000) || 1;

    setSecado((prevState) => ({
      ...prevState,
      pesoTotal: totalWeight,
      foliosTotal: totalFoliosSecado,
      entradaId: e.target.value.trim(),
      productor: selectedOption?.dataset?.productor || prevState.productor,
      region: selectedOption?.dataset?.region || prevState.region,
      municipio: selectedOption?.dataset?.municipality || prevState.municipio,
      comunidad: selectedOption?.dataset?.community || prevState.comunidad,
      tipo: selectedOption?.dataset?.tipo || prevState.tipo,
      caracteristica:
        selectedOption?.dataset?.caracteristica || prevState.caracteristica,
    }));

    setSecadoEntries(() => {
      let remainingWeight = totalWeight;

      const newSecadoEntries = Array.from(
        { length: totalFoliosSecado },
        (_, index) => {
          let folioWeight;
          if (index === totalFoliosSecado - 1) {
            folioWeight = remainingWeight;
          } else {
            folioWeight = Math.min(remainingWeight, 1000);
            remainingWeight -= folioWeight;
          }

          return {
            id: index + 1,
            folio: latestId + index + 1,
            claveDescarga:
              'P-' + e.target.value.trim() + '-' + (latestId + index + 1),
            peso: folioWeight.toFixed(2).toString(),
            pergamino: '',
            pergaminera: '',
            merma: '',
          };
        },
      );

      return newSecadoEntries;
    });
  };

  return (
    <Card direction="column" w="100%">
      <Flex
        justify="space-between"
        p="20px"
        mb="20px"
        borderRadius="16px"
        bgColor={balanceBg}
        bgImage={balanceImg}
        bgPosition="right"
        bgSize="cover"
      >
        <Flex align="center" justify="space-between" w="100%">
          <Flex alignItems="center" gap="15px" flexDirection="row" me="14px">
            <MdCenterFocusWeak color="white" size={'20px'} />
            <Text
              color="white"
              fontSize="20px"
              fontWeight="700"
              lineHeight="100%"
            >
              Selección de entrada
            </Text>
          </Flex>
        </Flex>
      </Flex>
      <FormControl>
        <SimpleGrid
          columns={{ base: '1', md: '3', lg: '3' }}
          gap={{ sm: '10px', md: '26px' }}
          mb={'1rem'}
        >
          <Flex direction="column">
            <Flex alignItems={'center'}>
              <FormLabel
                display="flex"
                ms="4px"
                fontSize="sm"
                fontWeight="500"
                color={textColor}
                mb="8px"
              >
                Entrada ID
              </FormLabel>
            </Flex>
            <Select
              borderColor={isSubmitted && !secado.entradaId ? 'red.500' : null}
              value={secado.entradaId || ''}
              onChange={(e) => {
                handleEntradaSelection(e);
              }}
              onFocus={handleFocus}
              variant="auth"
              placeholder="Entrada ID"
            >
              {uniqueEntradas.length > 0 &&
                uniqueEntradas.map((x) => (
                  <option
                    key={x.id}
                    value={x.entradaId}
                    data-productor={x.productor || 'No especificado'}
                    data-region={x.region || 'No especificado'}
                    data-municipality={x.municipio || 'No especificado'}
                    data-community={x.comunidad || 'No especificado'}
                    data-tipo={x.tipo || 'No especificado'}
                    data-caracteristica={x.caracteristica || 'No especificado'}
                  >
                    {x.entradaId}
                  </option>
                ))}
            </Select>
          </Flex>
          <Flex direction="column">
            <FormLabel
              display="flex"
              ms="4px"
              fontSize="sm"
              fontWeight="500"
              color={textColor}
              mb="8px"
            >
              Kilogramos totales
            </FormLabel>
            <InputGroup>
              <Input
                disabled
                borderColor={
                  isSubmitted && !secado.pesoTotal && !secado.entradaId
                    ? 'red.500'
                    : null
                }
                value={secado.pesoTotal}
                borderRadius="6px"
                height="40px"
                fontSize="md"
                placeholder="Kilogramos totales"
                variant="auth"
                size="lg"
              />
            </InputGroup>
          </Flex>
          <Flex direction="column">
            <FormLabel
              display="flex"
              ms="4px"
              fontSize="sm"
              fontWeight="500"
              color={textColor}
              mb="8px"
            >
              Folios de secado generados
            </FormLabel>
            <InputGroup>
              <Input
                disabled
                borderColor={
                  isSubmitted && !secado.foliosTotal && !secado.entradaId
                    ? 'red.500'
                    : null
                }
                value={secado.foliosTotal}
                borderRadius="6px"
                height="40px"
                fontSize="md"
                placeholder="Folios de secado"
                variant="auth"
                size="lg"
              />
            </InputGroup>
          </Flex>
          <Flex direction="column">
            <FormLabel
              display="flex"
              ms="4px"
              fontSize="sm"
              fontWeight="500"
              color={textColor}
              mb="8px"
            >
              Productor
            </FormLabel>
            <InputGroup>
              <Input
                disabled
                borderColor={
                  isSubmitted && !secado.productor ? 'red.500' : null
                }
                value={secado.productor}
                borderRadius="6px"
                height="40px"
                fontSize="md"
                placeholder="Productor"
                variant="auth"
                size="lg"
              />
            </InputGroup>
          </Flex>
          <Flex direction="column">
            <FormLabel
              display="flex"
              ms="4px"
              fontSize="sm"
              fontWeight="500"
              color={textColor}
              mb="8px"
            >
              Región
            </FormLabel>
            <InputGroup>
              <Input
                disabled
                borderColor={isSubmitted && !secado.region ? 'red.500' : null}
                value={secado.region}
                borderRadius="6px"
                height="40px"
                fontSize="md"
                placeholder="Región"
                variant="auth"
                size="lg"
              />
            </InputGroup>
          </Flex>
          <Flex direction="column">
            <FormLabel
              display="flex"
              ms="4px"
              fontSize="sm"
              fontWeight="500"
              color={textColor}
              mb="8px"
            >
              Municipio
            </FormLabel>
            <InputGroup>
              <Input
                disabled
                borderColor={
                  isSubmitted && !secado.municipio ? 'red.500' : null
                }
                value={secado.municipio}
                borderRadius="6px"
                height="40px"
                fontSize="md"
                placeholder="Municipio"
                variant="auth"
                size="lg"
              />
            </InputGroup>
          </Flex>
        </SimpleGrid>
      </FormControl>
    </Card>
  );
}
